import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
    {
        icon: <SettingsSuggestRoundedIcon fontSize="large" />,
        title: 'AI-Driven Campaigns',
        description:
            'Leverage artificial intelligence to create realistic and targeted phishing simulations that mirror real-world threats.',
    },
    {
        icon: <ConstructionRoundedIcon fontSize="large" />,
        title: 'Customizable Simulations',
        description:
            'Tailor phishing campaigns to match your organization’s needs, from email templates to scheduling.',
    },
    {
        icon: <ThumbUpAltRoundedIcon fontSize="large" />,
        title: 'User Awareness Tracking',
        description:
            'Monitor and analyze user responses to identify high-risk individuals and improve cybersecurity awareness.',
    },
    {
        icon: <AutoFixHighRoundedIcon fontSize="large" />,
        title: 'Innovative Training Tools',
        description:
            'Stay ahead with advanced training features designed to reduce human error and strengthen your organization’s defenses.',
    },
    {
        icon: <SupportAgentRoundedIcon fontSize="large" />,
        title: 'Comprehensive Support',
        description:
            'Access dedicated support for configuring campaigns, interpreting reports, and enhancing security protocols.',
    },
    {
        icon: <QueryStatsRoundedIcon fontSize="large" />,
        title: 'Detailed Analytics',
        description:
            'Gain actionable insights into campaign performance, user interactions, and areas for improvement.',
    },
];

export default function Features() {
    return (
        <Box
            id="features"
            sx={{
                pt: 12,
                pb: 6,
                bgcolor: 'background.paper',
                color: 'text.primary',
            }}
        >
            <Container>
                {/* Section Header */}
                <Box textAlign="center" mb={6}>
                    <Typography
                        variant="h4"
                        component="h2"
                        align="center"
                        sx={{ fontWeight: '600', mb: 2 }}
                    >
                        Features
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        Discover how PhishIntel revolutionizes cybersecurity training with AI-driven phishing simulations and customizable campaigns.
                    </Typography>
                </Box>

                {/* Features Grid */}
                <Grid container spacing={4}>
                    {items.map((item, index) => (
                        <Grid sx={{
                            mb: 4
                        }} item xs={12} sm={6} md={4} key={index}>
                            <Card
                                sx={{
                                    pt: 4,
                                    pb: 1,
                                    px: 3,
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                    borderRadius: 2,
                                }}
                            >
                                {/* Icon */}
                                <Box sx={{ color: 'primary.main', mb: 2 }}>{item.icon}</Box>
                                {/* Title */}
                                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                                    {item.title}
                                </Typography>
                                {/* Description */}
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {item.description}
                                </Typography>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}
